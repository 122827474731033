<template>
  <div class="right-content">
    <div class="top">
      <div class="title-mod">
        <div class="left">
          <div class="ikey-auth"> 
          </div>
          <span v-for="(m,index) in statistics" v-bind:key="index" :class="{active:m.status===status}" @click="statusChange(m.status)">
            {{ m.name }}(<i>{{ m.num }}</i>)
          </span>
        </div>
      </div>
<!--      <div class="cate">
        <div class="item">全部</div>
        <div class="item active">策划方案</div>
        <div class="item">平面方案</div>
        <div class="item">产品技术</div>
        <div class="item">行业报告</div>
        <div class="item">落地案例</div>
      </div>-->
    </div>
    <!--  列表  -->
    <div class="my-work-list scheme-list-mod" v-loading="loading" element-loading-background="rgba(245,245,245,1)">
      <ul>
        <li v-for="(m,index) in data_list" v-bind:key="index">
          <div class="pic">
            <router-link :to="'/detail/'+m.id">
              <img :src="m.img" :alt="m.title">
            </router-link>
          </div>
          <h3>
            <router-link :to="'/detail/'+m.id">{{ m.title }}</router-link>
          </h3>
          <div class="desc">{{ m.description }}</div>
          <div class="info">
            <div class="author"><img :src="m.user.avatar" alt=""><span>{{ m.user.nickname }}</span></div>
            <div class="viewer"><img src="/static/iconImg/icon-view.png" alt=""><span>{{ m.view }}</span></div>
          </div>
        </li>
      </ul>
    </div>
    <!-- 页码-->
    <div class="pagination">
      <ul>
        <li @click="prevPage" :class="{hide:current_page === 1}">
          <a class="arrow" href="javascript:;">
            <img src="static/iconImg/icon-left-arrow.png" alt="">
          </a>
        </li>
        <li v-for="page in last_page" :key="page" @click="pagination(page)">
          <a href="javascript:;"  :class="{active: current_page === page}">{{page}}</a>
        </li>
        <li @click="nextPage" :class="{hide:current_page === last_page || last_page===0}">
          <a class="arrow" href="javascript:;">
            <img src="static/iconImg/icon-right-arrow.png" alt="">
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import user from "@/api/user";
//import article from "@/api/article";

export default {
  name: "home",
  components: {},
  data() {
    return {
      keyword:'',
      per_page: 12,
      total: 0,
      current_page: 1,
      last_page: 0,
      data_list: [],
      codeUrl: '',
      cid: 0,
      status: 0,
      statistics:[],
      loading:false
    }
  },
  created() {
    this.getList();
    this.getStatusStatistics();
  },
  methods: {
    pagination(page) {
      if (this.current_page == page) {
        return false;
      } else {
        this.current_page = page;
        this.getList();
      }
    },
    //上一页
    prevPage() {
      if (this.current_page <= 1) {
        return false;
      } else {
        this.current_page = this.current_page-1;
        this.getList();
      }
    },
    //下一页
    nextPage() {
      if (this.current_page >= this.last_page) {
        return false;
      } else {
        this.current_page = this.current_page+1;
        this.getList();
      }
    },
    //状态及统计
    getStatusStatistics(){
      user.getStatusStatistics({param:'no'}).then(res=>{
        this.statistics = res.data;
      })
    },
    //改变状态
    statusChange(status){
      this.status = status;
      this.getList();
    },
    //调取 列表数据
    getList() {
      this.loading = true
      user.getArticleMy({
        keyword: this.keyword,
        cid: this.cid,
        status: this.status,
        pagesize:this.per_page,
        page: this.current_page
      }).then(res => {
        if (res) {
          this.data_list = res.data.data;
          this.per_page = res.data.per_page;
          this.total = res.data.total;
          this.current_page = res.data.current_page;
          this.last_page = res.data.last_page;
          this.loading = false
        } else {
          console.log(res);
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
</style>